import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 93px;
    margin-top: 56px;
    div {
        &.card {
            min-width: 296px;
            max-width: 296px;
            min-height: 189px;
            max-height: 189px;
            display: flex;
            align-items: center;
            button {
                bottom: -23px;
            }
        }
    }

    ${breakpoint('md')`
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    `}

    ${breakpoint('lg')`
        flex-direction: row;
        gap:16px;
        row-gap:105px;
        flex-wrap: wrap;
        width: 80%;
        justify-content: center;
        margin: 50px auto 46px auto;
            
                div {
                &.card {
                    button {
                        bottom: -16px;
                    }
                }
            }
                
    `}
`;

export const ContentText = styled.div`
    margin: 15px auto 0px auto;
    width: 253px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.colors.backgrounds.gray450};
    border-radius: 10px;
    &.shortly {
        padding-top: 0px;
        background: ${(props) => props.theme.colors.backgrounds.gray450};
        h3 {
            color: ${(props) => props.theme.colors.accents.base.yellow};
            font-family: ${(props) => props.theme.fonts.mon_bold};
            font-size: 24px;
            line-height: 29px;
        }
    }
    p {
        font-family: ${(props) => props.theme.fonts.lf_bold};
        max-width: 200px;
        font-size: 16px;
        line-height: 19px;
        color: ${(props) => props.theme.colors.text.gray};
        text-align: center;
        b {
            color: ${(props) => props.theme.colors.accents.base.yellow};
        }
    }
`;

export const ContainerImg = styled.div`
    position: absolute;
    display: flex;
    background: ${(props) => props.theme.colors.text.white};
    width: 243px;
    min-height: 89px;
    border-radius: 12px;
    top: -50px;
    left: 0;
    right: 0;
    margin: auto;
    img {
        position: relative;
        width: 150px;
        top: 0;
    }
`;
