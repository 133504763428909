import React, { useState } from 'react';
import { AuxBanner, Breadcrumbs, ButtonGoBack, Layout, SEO } from '@componentsShared';
import { WrapperBody, WrapperGames, WrapperTop } from './styled';
import Viewport from 'src/shared/hooks/viewport';
import { graphql, useStaticQuery } from 'gatsby';

import { ContainerGames } from 'src/components/organisms/containerGames';
import { ButtonsGames } from 'src/components/organisms/buttonsGames';
import { getMetadataPage } from '@utils/metadata';

const Games = () => {
    const [filter, setFilter] = useState();
    const dataGame = useStaticQuery(graphql`
        query auxBannerQuery {
            allContentfulBanner(filter: { game: { eq: "Juegos" } }) {
                nodes {
                    bannerType
                    game
                    mobileImage {
                        file {
                            url
                        }
                    }
                    desktopImage {
                        file {
                            url
                        }
                    }
                    icon {
                        file {
                            url
                        }
                        title
                    }
                    adText {
                        raw
                    }
                    mobileText {
                        raw
                    }
                }
            }
            allContentfulCardsDeInternaDeJuegos {
                nodes {
                    title
                    card {
                        title
                        image {
                            file {
                                url
                            }
                            description
                        }
                        richText {
                            raw
                        }
                        slug
                        id
                        state
                    }
                }
            }
            allContentfulPaginaInternaFooter(filter: { page: { eq: "Juegos" } }) {
                nodes {
                    slug
                    metaData {
                        title
                        descripcion
                        keyWords
                        image {
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const viewport = Viewport();

    let crumbs = [
        {
            crumbLabel: 'Inicio',
            pathname: '/'
        },
        {
            crumbLabel: 'Juegos',
            pathname: 'juegos/'
        }
    ];

    const {
        allContentfulCardsDeInternaDeJuegos: { nodes },
        allContentfulPaginaInternaFooter: {
            nodes: [page]
        }
    } = dataGame;

    return (
        <Layout>
            <SEO {...getMetadataPage(page.metaData, page.slug)} />
            <WrapperGames>
                {viewport.mobile && <ButtonGoBack />}
                {!viewport.mobile && <Breadcrumbs crumbs={crumbs} />}
                <WrapperTop>
                    <AuxBanner auxDataBanner={dataGame} />
                </WrapperTop>
                <WrapperBody>
                    {viewport.mobile && (
                        <ButtonsGames games={nodes[0].card} setFilter={setFilter} filter={filter} />
                    )}
                    <ContainerGames games={nodes[0].card} filter={filter} />
                </WrapperBody>
            </WrapperGames>
        </Layout>
    );
};

export default Games;
