import React, { useContext, useEffect, useState } from 'react';
import { Button, Redirect, Toast, UserData } from '@componentsShared';
import { CardGame } from 'src/components/molecules/gamesHome/styled';
import { Container, ContainerImg, ContentText } from './styled';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { IGame, IPropsCards } from './types';
import Viewport from 'src/shared/hooks/viewport';
import { AppContextUser } from 'src/shared/context/user';
import { carServices, lotteryToCar } from '@services/car';
import { IBet } from '@utils/egoi';

declare type ObjToast = {
    state: boolean;
    html: string;
    text: string;
    duracion: number;
};

export const ContainerGames = (props: IPropsCards) => {
    const viewport = Viewport();
    const { games, filter } = props;
    const [dataGame, setDataGame] = useState<IGame[]>(games);
    const { dataUser } = useContext(AppContextUser);
    const [toastState, setToastState] = useState<ObjToast>(
        {
            state: false,
            html: '',
            text: '',
            duracion: 0
        }
    );

    useEffect(() => {
        if (filter) {
            const newData = games.filter((game) => game === filter);
            setDataGame(newData);
        } else {
            setDataGame(games);
        }
    }, [filter]);
    useEffect(() => {
        viewport.desktop && setDataGame(games);
    }, [viewport]);

    const validarLoteriasACerrar = (lotteries: lotteryToCar[]) => {
        let uniqueLotteries = lotteries.filter((lot, index, self) => {
            return self.findIndex(l => l.loterieId === lot.loterieId) === index
        });
        const now = new Date();
        const currentHour = now.getHours();
        const currentMinute = now.getMinutes();

        const nextHalfHour = new Date();
        nextHalfHour.setMinutes(currentMinute < 30 ? 30 : 0);
        nextHalfHour.setHours(currentHour + (currentMinute < 30 ? 0 : 1));

        const loteriasProximasAVencer =
            uniqueLotteries.filter((obj) => {
                if (obj.horaCierre) {
                    const [hora, minutos] = obj.horaCierre?.split(':');
                    const loteriaVencimiento = new Date();
                    loteriaVencimiento.setHours(parseInt(hora));
                    loteriaVencimiento.setMinutes(parseInt(minutos));
                    return loteriaVencimiento > now && loteriaVencimiento <= nextHalfHour;
                }
            }) || ([] as lotteryToCar[]);

        const tiempoRestante = (loteriaProximaAVencer: lotteryToCar) => {
            if (loteriaProximaAVencer?.horaCierre) {
                const [hora, minutos] = loteriaProximaAVencer.horaCierre?.split(':');
                const dateLoteria = new Date();
                dateLoteria.setHours(parseInt(hora));
                dateLoteria.setMinutes(parseInt(minutos));
                dateLoteria.setSeconds(0);

                const time = dateLoteria.getTime() - now.getTime();

                const minutosRestantes = Math.floor(time / (1000 * 60));
                const segundosRestantes = Math.floor((time % (1000 * 60)) / 1000);

                return [minutosRestantes < 10 ? '0' + minutosRestantes : minutosRestantes, 
                        segundosRestantes < 10 ? '0' + segundosRestantes : segundosRestantes];
            }
            return ['00', '00'];
        };

        const objToast: ObjToast = {
            state: false,
            text: '',
            html: '',
            duracion: 0
        };

        loteriasProximasAVencer.forEach((item: lotteryToCar) => {
            if (item?.horaCierre) {
                objToast.state = true;
                const tiempo = tiempoRestante(item);
                const text: string = `El sorteo de ${item.desLoteria} que está en tu carrito, cierra en ${tiempo[0]}:${tiempo[1]} <br>`;
                objToast.html += text;
                objToast.duracion = 10;
            }
        });

        setToastState(objToast);
        setTimeout(() => {
            setToastState({
                state: false,
                text: '',
                html: '',
                duracion: 0
            });
        }, 10000);
    };

    const fetchDataCart = async () => {
        await carServices
            .getCar(dataUser.token, dataUser.tipoDocumento, dataUser.usuario)
            .then((data) => {
                if (data.data.getCart) {
                    const bets: IBet[] = data.data.getCart.bets;
                    const lotteries: lotteryToCar[] = [] as lotteryToCar[];
                    bets.forEach((bet: IBet) => {
                        bet.loteries.forEach((lottery: lotteryToCar) => {
                            lotteries.push(lottery);
                        });
                    });
                    validarLoteriasACerrar(lotteries);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if(dataUser?.token) {
            fetchDataCart();
        }
    }, [dataUser]);

    return (
        <Container>
            {dataGame.map((game: any) => (
                <CardGame className="card" key={game.id}>
                    <ContainerImg>
                        <img src={game.image.file.url} alt={game.image.description} />
                    </ContainerImg>
                    <ContentText className={game.state ? 'textValue' : 'shortly'}>
                        <p>{documentToReactComponents(JSON.parse(game.richText.raw))}</p>
                    </ContentText>
                    {game.state ? (
                        <Redirect url={'juegos/' + game.slug}>
                            <Button
                                text="Jugar"
                                disabled={!game.state}
                                typeButton="btnDisableBlack"
                            ></Button>
                        </Redirect>
                    ) : (
                        <Button
                            text="Jugar"
                            disabled={!game.state}
                            typeButton="btnDisableBlack"
                        ></Button>
                    )}
                </CardGame>
            ))}
            {toastState.state === true && <Toast text={toastState.text} html={toastState.html} duracion={toastState.duracion} icon="cross" type="danger" />}
        </Container>
    );
};
