import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const WrapperGames = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    // padding: 20px 0px 50px 0px;
    padding: 28px 0 40px;

    ul:first-child {
        padding-left: 67px;
    }
`;

export const WrapperTop = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    margin-top: 50px;
    margin-bottom: 16px;

    ${breakpoint('md')`
        margin-top: 49px;
         margin-bottom: 56px;
    `}

    ${breakpoint('lg')`
        min-height: auto;
    `}
`;

export const WrapperBody = styled.div`
    margin: auto;
`;
